<template>
  <div>
    <div>
      <video
        id="myvideo"
        ref="videoId"
        autoplay="true"
        controls
        muted
        v-if="videoSrc"
        class="videoBox"
      >
        <source :src="videoSrc" />
      </video>
    </div>
    <div class="wrap">
      <span class="fb-img">{{!videoSrc ? '开始录制' : '重新录制'}}</span>
      <input
        ref="changeInput"
        type="file"
        accept="video/*"
        capture="user"
        @change="changeVideo"
        class="form-control"
      />
    </div>
    <div class="footer_box" >
      <p class="footer_text">您需要在视频里说一句话：</p>
      <p class="footer_hint">我是***，我自愿在龙港注册个体户</p>
      <div class="footer_btn" v-if="videoSrc" @click="handleuploadFaceVideo">确定</div>
    </div>
  </div>
</template>

<script>
import { setInterval, clearInterval } from "timers";
import { uploadVideoFile, uploadFaceVideo } from "@api/user";
import { Indicator, Toast } from "mint-ui";
export default {
  data() {
    return {
      videoSize: "",
      videoSrc: "",
      videoLength: "",
      isAndroid: false,
      fileAndroid: {},
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
      gifSetTime: false,
      gif: ""
    };
  },
  created() {
    //判断终端
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      console.log("isAndroid");
      this.isAndroid = true;
    } else if (isiOS) {
      console.log("isiOS");
      this.isAndroid = false;
    }
  },
  mounted() {},
  methods: {
    handleUpload(file) {
      if (!file) {
        return;
      }
       this.videoSrc = null;
      Indicator.open("视频生成中，请稍后...");
      let timer = setTimeout(() => {
        Indicator.close();
      }, 20000);
      let imageFormData = new FormData();
      imageFormData.append("uploadFile", file);
      uploadVideoFile(imageFormData).then(res => {
        Indicator.close();
        console.log(res);
        this.videoSrc = res.data;
      });
    },

    //input文件走向
    changeVideo(e) {
      console.log(e)
      this.videoSrc = null;
      var file = e.target.files[0];
      const video = document.getElementById("myvideo");

      if (file !== undefined) {
        //判断走向
        if (this.isAndroid) {
          this.handleUpload(file);
        } else {
          this.iphoneFile(file);
        }
      }
    },
    //IOS拍摄视频
    iphoneFile(file) {
      console.log(file);

      const that = this;
      //视频字节大小
      this.videoSize = file.size;

      var url = null;
      //file转换成blob
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      this.videoSrc = url;
      if (file.size < 2100000 && file.size > 500000) {
        this.handleUpload(file);
      } else if (file.size >= 2100000) {
        this.$vux.toast.text("视频太大，请限制在10秒内");
      } else {
        this.$vux.toast.text("视频录制不能少于5秒");
      }
    },
    // 人脸视频关联个体户
    handleuploadFaceVideo(){
      uploadFaceVideo({videoOssUrl: this.videoSrc}).then(res =>{
        if (res.code ===200) {
          Toast('上传成功')
          this.$router.push('/UploadVideo/landingPage');
        }
      })
    }
  }
};
</script>
<style scoped lang='less'>
.footer_box {
  margin: 20px 0;
  width: 100%;
  bottom: 0;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .footer_btn {
    width: 335px;
    height: 40px;
    background: #c92e26;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
  }
  .footer_text {
    margin-bottom: 10px;
    color: #999;
    font-size: 12px;
  }
  .footer_hint {
    margin-bottom: 20px;
    font-size: 16px;
    color: #000;
  }
}
.videoBox {
  margin-top: 100px;
  width: 100%;
  height: 300px;
}
.wrap{
  position: relative;
  margin-top: 20%;
  display: flex;
    align-items: center;
    justify-content: center;
}
.form-control {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    height: 100%;
}
.form-control:active{
  background-color: transparent;
}
.fb-img {
    border-radius: 4px;
    background-color: #c92e26;
    color: #fff;
    display: inline-block;
    height: 34px;
    line-height: 34px;
    padding: 6px 12px;
    text-align: center;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 60%;
}
</style>
